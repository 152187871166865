export function Trash() {
  return (
    <svg viewBox="0 0 19 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2235_44)">
      < path d="M5.98225 6.23489V19.7693M9.14028 6.23489V19.7693M12.2983 6.23489V19.7693M2.12944 3.4107V21.6912C2.12944 22.1875 2.53548 22.5935 3.03174 22.5935L15.2579 22.5484C15.7541 22.5484 16.1601 22.1424 16.1601 21.6461V3.4107M0.451172 3.15806H18.0189M12.181 2.67082V1.35347C12.181 0.857205 11.775 0.451172 11.2787 0.451172H6.88454C6.38828 0.451172 5.98225 0.857205 5.98225 1.35347V2.67082" strokeLinecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2235_44">
          <rect width="18.47" height="23.0446"/>
        </clipPath>
      </defs>
    </svg>
  );
}
