export function PlusOpen() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2821_393)">
        <path d="M0 11.5H24"/>
        <path d="M12 24L12 0"/>
      </g>
    </svg>
  );
}
