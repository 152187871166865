export function ColorEye() {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64">
    <path d="M62.197 28.996a5.41 5.41 0 0 1 0 6.007C58.477 40.571 48.827 51.932 32 51.932S5.523 40.57 1.804 35.003a5.41 5.41 0 0 1 0-6.007c3.719-5.568 13.37-16.93 30.196-16.93s26.477 11.362 30.197 16.93z" fill="none"></path>
    <circle cx="32" cy="31.999" r="16.106" fill="var(--primary-color)"></circle>
    <path d="M62.202 34.995C58.48 40.58 48.824 51.93 32 51.93a34.76 34.76 0 0 1-3.133-.129c8.9-4.109 15.572-11.646 17.58-21.192 1.143-5.454.627-10.909-1.216-15.94 8.846 3.686 14.374 10.43 16.972 14.319a5.402 5.402 0 0 1 0 6.007z" opacity=".15" fill="#000000"></path>
    <path d="M32 52.821c-17.475 0-27.455-12.116-30.935-17.326a6.278 6.278 0 0 1-.001-6.994C4.544 23.293 14.524 11.178 32 11.178c17.478 0 27.457 12.115 30.936 17.323a6.28 6.28 0 0 1 0 6.994C59.457 40.705 49.476 52.821 32 52.821zm0-39.865c-16.618 0-26.137 11.562-29.458 16.533a4.508 4.508 0 0 0 0 5.02C5.865 39.48 15.384 51.044 32 51.044c16.619 0 26.138-11.564 29.457-16.535a4.503 4.503 0 0 0 0-5.02C58.137 24.518 48.62 12.956 32 12.956z" fill="var(--color-content-primary)"></path>
    <path d="M33.87 48.853c-8.569 0-15.54-6.97-15.54-15.54 0-7.881 6.412-14.293 14.294-14.293 7.194 0 13.047 5.853 13.047 13.047 0 6.506-5.294 11.8-11.801 11.8-5.819 0-10.553-4.735-10.553-10.554 0-5.132 4.175-9.306 9.307-9.306 4.444 0 8.059 3.616 8.059 8.06a6.822 6.822 0 0 1-6.813 6.814 5.574 5.574 0 0 1-5.567-5.568 4.326 4.326 0 0 1 4.321-4.32 3.077 3.077 0 0 1 3.073 3.074.888.888 0 1 1-1.778 0c0-.715-.58-1.296-1.295-1.296a2.546 2.546 0 0 0-2.543 2.542c0 2.09 1.7 3.79 3.789 3.79a5.04 5.04 0 0 0 5.035-5.036 6.29 6.29 0 0 0-6.28-6.283c-4.153 0-7.53 3.378-7.53 7.529 0 4.839 3.936 8.776 8.775 8.776 5.527 0 10.023-4.497 10.023-10.022 0-6.214-5.055-11.27-11.269-11.27-6.902 0-12.516 5.615-12.516 12.516 0 7.588 6.173 13.762 13.762 13.762a.89.89 0 1 1 0 1.778z" fill="#262626"></path>
    <path d="M32 48.993c-9.37 0-16.995-7.623-16.995-16.993 0-9.372 7.624-16.996 16.995-16.996S48.995 22.628 48.995 32c0 9.37-7.625 16.993-16.995 16.993zm0-32.211c-8.39 0-15.217 6.826-15.217 15.218 0 8.39 6.826 15.216 15.217 15.216 8.391 0 15.218-6.827 15.218-15.216 0-8.392-6.827-15.218-15.218-15.218zM26.66 7.628a.888.888 0 0 1-.845-.616L24.03 1.498a.888.888 0 1 1 1.691-.547l1.785 5.514a.888.888 0 0 1-.845 1.163zM14.995 11.223a.887.887 0 0 1-.704-.345l-3.548-4.582a.89.89 0 0 1 1.406-1.089l3.548 4.583a.89.89 0 0 1-.702 1.433zM37.346 7.628a.888.888 0 0 1-.845-1.162L38.285.95a.889.889 0 1 1 1.691.547l-1.784 5.514a.888.888 0 0 1-.846.616zM49.013 11.223a.89.89 0 0 1-.703-1.433l3.548-4.583a.89.89 0 0 1 1.406 1.089l-3.548 4.582a.886.886 0 0 1-.703.345zM39.13 63.663a.89.89 0 0 1-.845-.614l-1.784-5.514a.889.889 0 0 1 1.69-.549l1.785 5.514a.889.889 0 0 1-.845 1.163zM52.561 59.136a.888.888 0 0 1-.703-.344L48.31 54.21a.891.891 0 0 1 .158-1.248.891.891 0 0 1 1.248.158l3.548 4.582a.891.891 0 0 1-.703 1.434zM24.876 63.663a.89.89 0 0 1-.845-1.163l1.784-5.514a.89.89 0 0 1 1.691.549l-1.785 5.514a.89.89 0 0 1-.845.614zM11.445 59.136a.887.887 0 0 1-.702-1.433l3.548-4.581a.888.888 0 1 1 1.406 1.087L12.15 58.79a.886.886 0 0 1-.704.346z" fill="var(--color-content-primary)"></path></svg>
  );
}